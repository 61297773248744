export const getTaskOptions = {
  Entities: [
    {
      Properties: {
        Id: '1',
        Name: 'NPS time',
      },
    },
    {
      Properties: {
        Id: '2',
        Name: 'Workshops',
        Items: [
          {
            Id: '3',
            Name: '» Data Work',
          },
          {
            Id: '4',
            Name: '» Resource Work',
          },
        ],
      },
    },
    {
      Properties: {
        Id: '5',
        Name: 'Acadre integration',
        Items: [
          {
            Id: '6',
            Name: 'Preparation',
            Items: [
              {
                Id: '7',
                Name: '» On-line support',
              },
              {
                Id: '8',
                Name: '» Off-line support',
              },
            ],
          },
        ],
      },
    },
    {
      Properties: {
        Id: '9',
        Name: 'Internal meetings (O13001)',
      },
    },
  ],
  Links: [
    {
      Href: 'http://localhost/tlp/api/v2/move-hours/projects/*/tasks',
      Rel: 'self',
    },
  ],
};
