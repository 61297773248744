import { useGetLocale } from 'src/components/global/LocaleProvider';
import { formatTime } from 'src/utils/time';
import { getDateStringFromSiteLocale } from 'src/utils/date';

export const useExcelConfig = () => {
  const siteLocale = useGetLocale();

  return {
    ignoreColumns: ['__check__', 'split'],
    fileName: 'Move_hours.xlsx',
    sheetName: 'Move_hours',
    titleName: 'TimeLog - Move_hours',
    valueParser: {
      date: (value: number | string) => getDateStringFromSiteLocale(new Date(value), siteLocale),
      projectActualHours: (value: number | string) => formatTime(value, siteLocale),
    },
  };
};
