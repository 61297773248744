import { MenuItem } from '@mui/material';
import { useLayoutEffect, useRef, useState } from 'react';

import { useGetTaskOptions } from 'src/apis/moveHoursAPI';
import { Autocomplete } from 'src/components/mui-components';
import { HighlightMatchingText } from 'src/components/utils/HighlightMatchingText';

export const TaskDropdown = ({ selectedProjectId, params }: any) => {
  const { taskOptions } = useGetTaskOptions(selectedProjectId || '');

  const [selectedTask, setSelectedTask] = useState<any>('');

  const { id, value, field, hasFocus } = params;
  const ref = useRef<HTMLInputElement>(null);

  useLayoutEffect(() => {
    if (hasFocus && ref.current) {
      ref?.current?.focus();
    }
  }, [hasFocus, value]);

  const flattenTaskOptions = (options: any[]): any[] => {
    const flattened: any[] = [];

    const flatten = (opts: any[], depth = 1) => {
      opts.forEach((option: any) => {
        flattened.push({
          id: option.id,
          name: option.name,
          depth,
          isClickable: !option.items || option.items.length === 0,
        });
        if (option.items) {
          flatten(option.items, depth + 1);
        }
      });
    };

    flatten(options);
    return flattened;
  };

  const handleTaskChange = (newValue: any) => {
    setSelectedTask(newValue);
  };

  return (
    <Autocomplete
      inputRef={ref}
      size="small"
      placeholder="Select task"
      options={selectedProjectId ? flattenTaskOptions(taskOptions) : []}
      getOptionLabel={(option: any) => option.name}
      isOptionEqualToValue={(o: any, v: any) => o.name === v.name}
      disableClearable
      componentsProps={{ popper: { style: { width: 200 } } }}
      disabled={!selectedProjectId}
      onKeyDown={(e) => {
        e.stopPropagation();
        if (e.key === 'Enter' || e.key === 'Escape') {
          // Find the cell element and focus it
          const cell = document.querySelector(`[data-id="${id}"] [data-field="${field}"]`);
          if (cell) {
            (cell as HTMLElement).focus();
          }
        }
      }}
      renderOption={(props, option, state) =>
        option.isClickable ? (
          <li
            {...props}
            data-automation-id={`${option.name}Item`}
            key={`${option.name}-${state.index}`}
            style={{ paddingLeft: `${option.depth}rem`, whiteSpace: 'normal' }}
          >
            <HighlightMatchingText name={option.name as string} matchName={state.inputValue} />
          </li>
        ) : (
          <MenuItem
            key={`${option.name}-${state.index}`}
            sx={{ pl: option.depth * 2, whiteSpace: 'normal' }}
            disabled
          >
            {option.name}
          </MenuItem>
        )
      }
      onChange={(event, newValue) => {
        handleTaskChange(newValue);
      }}
      value={selectedTask || null}
      sx={{
        '& .MuiInputBase-input': {
          height: '0.7rem',
        },
        '& .MuiFormControl-root': {
          verticalAlign: 'initial',
        },
      }}
      data-automation-id="TaskNameDropdown"
    />
  );
};
