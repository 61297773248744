const array = Array(100);

function getRandomArbitrary(min: number, max: number): number {
  return parseFloat((Math.random() * (max - min) + min).toFixed(2));
}

const randomDecimal = (min: number, max: number, decimalPlaces: number): number => {
  const random = Math.random() * (max - min) + min;
  return parseFloat(random.toFixed(decimalPlaces));
};

function generateRows() {
  const rows = [];
  for (let index = 0; index <= array.length; index += 1) {
    rows.push({
      Properties: {
        Id: index,
        Date: '2024-09-19T00:00:00+01:00',
        ProjectNo: `${Math.floor(Math.random() * 100000)}-${Math.floor(Math.random() * 100)}`,
        ProjectName: `Project ${index}`,
        Wbs: randomDecimal(1, 5.5, 1),
        TaskName: `Task ${index} Name`,
        TaskNo: `T${Math.floor(Math.random() * 1000)}`,
        Comment: `Comment ${index}`,
        AdditionalText: '',
        EmployeeInitials: 'KLA',
        EmployeeName: 'Ang Kian Leong',
        NewProjectId: '',
        NewTaskName: '',
        ProjectActualHours: getRandomArbitrary(0, 20000),
        IsInvoiced: Math.random() < 0.2,
        BookedInvoiceId: 1, // This is for already booked invoice
      },
    });
  }
  return rows;
}

export const getMoveHours = {
  Entities: generateRows(),
  Properties: {
    ProjectsCount: '16',
    TotalProjectActualHours: '20003.0033',
    AdditionalCommentFieldName: 'Additional Comment',
  },
  Links: [{ href: '/move-hours', rel: 'self' }],
};
