import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import {
  DataGrid,
  dataGridCellBorderRightThickClassName,
} from 'src/components/mui-components/DataGrid';
import { useGetLocale } from 'src/components/global/LocaleProvider';
import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GRID_CHECKBOX_SELECTION_FIELD,
  GridCellCheckboxRenderer,
  GridColDef,
  GridColumnGroupingModel,
  GridPinnedRowsProp,
  GridRenderCellParams,
  GridSlots,
  useGridApiContext,
  useGridApiRef,
} from '@mui/x-data-grid-pro';

import { useFilterStore } from 'src/stores/FilterStore';
import { useTranslation } from 'react-i18next';
import { useGetMoveHours, useGetProjectOptions } from 'src/apis/moveHoursAPI';
import { Spinner, TextLink } from 'src/components/ui-components';
import { ViewOptionsChangeParameters } from 'src/components/layout/FilterLayout/types';
import { formatTime } from 'src/utils/time';
import { Autocomplete, IconButton } from 'src/components/mui-components';
import { CallSplit, Receipt, WarningAmberOutlined } from '@mui/icons-material';
import { getDateStringFromSiteLocale } from 'src/utils/date';
import { HighlightMatchingText } from 'src/components/utils/HighlightMatchingText';
import { MHSelectedFilterListStateKey } from '../../localStorageKeys';
import { Toolbar } from './Toolbar';

import styles from '../../MoveHours.module.scss';
import { NoData } from '../NoData';
import { SplitDialog } from '../SplitDialog';
import { TaskDropdown } from './TaskDropdown';
import { hiddenFields } from '../../constants';

interface ISearchProject {
  selectedViewOptions: {
    [key: string]: string;
  };
  onViewOptionsChange: (item: ViewOptionsChangeParameters[]) => void;
  filterSection?: any;
}

const getTogglableColumns = (columns: GridColDef[]) =>
  columns.filter((column) => !hiddenFields.includes(column.field)).map((column) => column.field);

const LoadingOverlay = () => <Spinner />;

const ProjectAutoCompleteCell = ({ params, projectOptions }: any) => {
  const { id, value, field, hasFocus } = params;
  const apiRefContext = useGridApiContext();
  const ref = useRef<HTMLInputElement>(null);

  const handleProjectChange = (newValue: any) => {
    apiRefContext.current.updateRows([{ id: params.id, newProjectId: newValue.id }]);
  };

  useLayoutEffect(() => {
    if (hasFocus && ref.current) {
      ref?.current?.focus();
    }
  }, [hasFocus, value]);

  return (
    <Autocomplete
      inputRef={ref}
      size="small"
      placeholder="Select project"
      options={projectOptions}
      getOptionLabel={(option: any) => option.name}
      isOptionEqualToValue={(o: any, v: any) => o.name === v.name}
      disableClearable
      componentsProps={{ popper: { style: { width: 200 } } }}
      onKeyDown={(e) => {
        e.stopPropagation();
        if (e.key === 'Enter' || e.key === 'Escape') {
          // Find the cell element and focus it
          const cell = document.querySelector(`[data-id="${id}"] [data-field="${field}"]`);
          if (cell) {
            (cell as HTMLElement).focus();
          }
        }
      }}
      renderOption={(props, option, state) => (
        <li
          data-automation-id={`${option.name}Item`}
          {...props}
          key={`${option.name}-${state.index}`}
        >
          <HighlightMatchingText name={option.name as string} matchName={state.inputValue} />
        </li>
      )}
      onChange={(event, newValue) => {
        handleProjectChange(newValue);
      }}
      value={
        value
          ? (() => {
              const project = projectOptions?.find((option: any) => option.id === value);
              return project && { name: project.name };
            })()
          : null
      }
      sx={{
        '& .MuiInputBase-input': {
          height: '0.7rem',
        },
        '& .MuiFormControl-root': {
          verticalAlign: 'initial',
        },
      }}
      data-automation-id="ProjectNameDropdown"
    />
  );
};

export const Table = ({
  selectedViewOptions,
  onViewOptionsChange,
  filterSection,
}: ISearchProject) => {
  const { t } = useTranslation('moveHours');
  const siteLocale = useGetLocale();

  const apiRef = useGridApiRef();
  const { filterQueryObj } = useFilterStore();
  localStorage.setItem(MHSelectedFilterListStateKey, JSON.stringify(filterQueryObj));

  const {
    isLoading,
    isSuccess,
    isEmpty,
    data: rows,
    summations,
    additionalCommentLabel,
  } = useGetMoveHours({ selectedFilterList: filterQueryObj }, selectedViewOptions);

  const { projectOptions } = useGetProjectOptions('1');

  const [selectedRowProject, setSelectedRowProject] = useState<any>([]);

  const handleRowSelectionChange = () => {
    const selectedRows = apiRef.current.getSelectedRows();
    const formattedRows = Array.from(selectedRows.values()).map((row) => ({
      projectId: row.projectId,
    }));

    setSelectedRowProject(formattedRows);
  };

  // Split Dialog
  const [showSplit, setShowSplit] = useState(false);

  const [columnsButtonEl, setColumnsButtonEl] = useState<HTMLButtonElement | null>(null);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        renderCell: (params) =>
          params.row.isInvoiced ? (
            <IconButton
              data-automation-id="TimeRegistrationCannotBeMovedIcon"
              title={t('InvoicedTimeRegistration')}
              size="small"
            >
              <WarningAmberOutlined fontSize="small" />
            </IconButton>
          ) : (
            <GridCellCheckboxRenderer {...params} />
          ),
      },
      {
        field: 'date',
        headerName: t('TableHeaderDate'),
        width: 100,
        valueFormatter: (value) => getDateStringFromSiteLocale(new Date(value), siteLocale),
        valueGetter: (value, row) => {
          if (row.id === 'TOTAL') {
            return row.name;
          }
          return value;
        },
        colSpan: (value, row) => {
          if (row.id === 'TOTAL') {
            return 2;
          }
          return undefined;
        },
      },
      {
        field: 'projectNo',
        headerName: t('TableHeaderProjectNo'),
        minWidth: 100,
        flex: 0.1,
        renderCell: (params: GridRenderCellParams) => (
          <TextLink
            key={0}
            className={styles.link}
            useTextColor
            href={`/ProjectManagement/Plan/Index/${params.row.projectId}`}
            title={params.value}
          >
            {params.value}
          </TextLink>
        ),
      },
      {
        field: 'projectName',
        headerName: t('TableHeaderProjectName'),
        minWidth: 250,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => (
          <TextLink
            key={0}
            className={styles.link}
            useTextColor
            href={`/ProjectManagement/Plan/Index/${params.row.projectId}`}
            title={params.value}
          >
            {params.value}
          </TextLink>
        ),
      },
      {
        field: 'wbs',
        headerName: t('TableHeaderWbs'),
        minWidth: 100,
      },
      {
        field: 'taskName',
        headerName: t('TableHeaderTaskName'),
        minWidth: 120,
        flex: 0.1,
      },
      {
        field: 'taskNo',
        headerName: t('TableHeaderTaskNo'),
        minWidth: 100,
        flex: 0.1,
      },
      {
        field: 'comment',
        headerName: t('TableHeaderComment'),
        minWidth: 120,
        flex: 0.1,
      },
      ...(additionalCommentLabel !== ''
        ? [
            {
              field: 'additionalText',
              minWidth: 150,
              headerName: additionalCommentLabel,
            },
          ]
        : []),
      {
        field: 'employeeInitials',
        headerName: t('TableHeaderEmployee'),
        minWidth: 120,
        flex: 0.1,
        headerClassName: `${dataGridCellBorderRightThickClassName}`,
        cellClassName: `${dataGridCellBorderRightThickClassName}`,
        renderCell: (params) => <span title={params.row.employeeName}>{params.value}</span>,
      },
      {
        field: 'newProjectId',
        headerName: t('TableHeaderProjectName'),
        minWidth: 180,
        flex: 0.2,
        renderCell: (params: any) => {
          if (params.id === 'TOTAL') {
            return null;
          }
          if (params.row.isInvoiced) {
            return <span title={params.row.projectName}>{params.row.projectName}</span>;
          }
          return <ProjectAutoCompleteCell params={params} projectOptions={projectOptions} />;
        },
      },
      {
        field: 'newTaskName',
        headerName: t('TableHeaderTaskName'),
        minWidth: 180,
        flex: 0.2,
        headerClassName: `${dataGridCellBorderRightThickClassName}`,
        cellClassName: `${dataGridCellBorderRightThickClassName}`,
        renderCell: (params) => {
          if (params.id === 'TOTAL') {
            return null;
          }
          if (params.row.isInvoiced) {
            return <span title={params.row.taskName}>{params.row.taskName}</span>;
          }
          return <TaskDropdown selectedProjectId={params.row.newProjectId} params={params} />;
        },
      },
      {
        field: 'projectActualHours',
        headerName: t('TableHeaderActualHours'),
        minWidth: 120,
        flex: 0.1,
        headerAlign: 'right',
        align: 'right',
        valueFormatter: (value) => formatTime(value, siteLocale),
      },
      // If isInvoiced then go to, /Financial/Invoicing/EditVoucher/74204
      {
        field: 'split',
        headerName: '',
        width: 50,
        hideable: false,
        display: 'flex',
        renderCell: (params: GridRenderCellParams) => {
          if (params.id === 'TOTAL') {
            return '';
          }
          if (params.row.isInvoiced) {
            return (
              <IconButton
                data-automation-id="InvoiceIcon"
                title={t('InvoiceText')}
                size="small"
                onClick={() => {
                  // eslint-disable-next-line no-alert
                  alert('Redirect to invoice page.');
                }}
              >
                <Receipt fontSize="small" />
              </IconButton>
            );
          }

          return (
            <IconButton
              data-automation-id="SplitIcon"
              title={t('SplitTimeRegistrationText')}
              size="small"
              onClick={() => {
                setShowSplit(true);
              }}
            >
              <CallSplit fontSize="small" />
            </IconButton>
          );
        },
      },
    ],
    [additionalCommentLabel, projectOptions, siteLocale, t],
  );

  const columnGroupingModel: GridColumnGroupingModel = [
    {
      groupId: 'selection',
      headerName: '',
      children: [{ field: GRID_CHECKBOX_SELECTION_FIELD }],
    },
    {
      groupId: 'currentProjectTaskGroup',
      headerName: t('TableHeaderCurrentProjectTask'),
      children: [
        { field: 'date' },
        { field: 'projectNo' },
        { field: 'projectName' },
        { field: 'wbs' },
        { field: 'taskName' },
        { field: 'taskNo' },
        { field: 'comment' },
        { field: 'additionalText' },
        { field: 'employeeInitials' },
      ],
    },
    {
      groupId: 'newProjectTaskGroup',
      headerName: t('TableHeaderNewProjectTask'),
      headerAlign: 'center',
      children: [{ field: 'newProjectId' }, { field: 'newTaskName' }],
    },
    {
      groupId: 'registration',
      headerName: t('TableHeaderRegistration'),
      headerAlign: 'center',
      children: [{ field: 'projectActualHours' }],
    },
    {
      groupId: 'action',
      headerName: '',
      headerAlign: 'center',
      children: [{ field: 'split' }],
    },
  ];

  const pinnedRows: GridPinnedRowsProp = {
    bottom: [...[summations]],
  };

  const applyProjectsUpdate = async () => {
    // eslint-disable-next-line no-console
    console.log('Appply project update');
  };

  // Height of the table
  const dataGridContainerRef = useRef<HTMLDivElement>(null);
  const [offSetTop, setOffSetTop] = useState<number>(0);

  // Set on initial load
  useEffect(() => {
    if (dataGridContainerRef.current) {
      setOffSetTop(dataGridContainerRef.current.offsetTop);
    }
  }, [isSuccess]);

  return (
    <div
      style={{ width: '100%', height: `calc(100vh - ${offSetTop + 20}px)` }}
      ref={dataGridContainerRef}
    >
      <DataGrid
        apiRef={apiRef}
        columns={columns}
        columnGroupingModel={columnGroupingModel}
        rows={!isEmpty ? rows : []}
        disableColumnMenu
        hideFooter
        checkboxSelection
        disableRowSelectionOnClick
        pinnedRows={!isEmpty ? pinnedRows : undefined}
        onRowSelectionModelChange={() => handleRowSelectionChange()}
        rowHeight={34}
        loading={isLoading}
        initialState={{
          columns: {
            columnVisibilityModel: {
              projectNo: false,
              wbs: false,
              additionalText: false,
            },
          },
        }}
        isRowSelectable={(params) => !params.row.isInvoiced}
        getRowClassName={(params) => (params.row.isInvoiced ? 'is-invoiced' : '')}
        slots={{
          toolbar: Toolbar as GridSlots['toolbar'],
          loadingOverlay: LoadingOverlay,
          // eslint-disable-next-line react/no-unstable-nested-components
          noRowsOverlay: () => <NoData onViewOptionsChange={onViewOptionsChange} />,
        }}
        // To hide the toolbar text
        localeText={{
          toolbarColumns: '',
          toolbarFilters: '',
          toolbarDensity: '',
          toolbarExport: '',
        }}
        slotProps={{
          panel: {
            anchorEl: columnsButtonEl,
            placement: 'bottom-end',
          },
          toolbar: {
            selectedRowProject,
            columns,
            setColumnsButtonEl,
            filterSection,
            applyProjectsUpdate,
            projectOptions,
          },
          columnsManagement: {
            getTogglableColumns,
          },
        }}
        sx={{
          fontSize: 12,
          '& .is-invoiced': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
          // Allow long text to wrap in the header
          '& .MuiDataGrid-columnHeaderTitle': {
            textOverflow: 'clip',
            whiteSpace: 'break-spaces',
            lineHeight: 'normal',
          },
        }}
        data-automation-id="MoveHoursTable"
      />
      <SplitDialog showSplit={showSplit} setShowSplit={setShowSplit} />
    </div>
  );
};
