import { getFetch } from 'src/utils/fetcher';
import { useQuery } from '@tanstack/react-query';
import { concatQuerys } from 'src/utils/url';
import { toCamel } from 'src/utils/object';
import { getProjectOptions as mock } from 'src/mocks/moveHours';
import { IProjectOptionsResponse } from '../types';

const GET_PROJECT_OPTIONS_URL_PATH = '/api/v2/move-hours/projects';
const GET_PROJECT_OPTIONS_MSW_STATUS_KEY = 'getProjectOptionsAPI';

const GET_PROJECT_OPTIONS_KEY = 'GET_PROJECT_OPTIONS_KEY';

const getProjectOptions = (filterId: string): Promise<IProjectOptionsResponse> =>
  getFetch({
    path: `${GET_PROJECT_OPTIONS_URL_PATH}${concatQuerys([{ param: 'filter', value: filterId }])}`,
    key: GET_PROJECT_OPTIONS_MSW_STATUS_KEY,
  });

export const useGetProjectOptions = (filterId: string) => {
  const { data, isError, ...restProps } = useQuery(
    [GET_PROJECT_OPTIONS_KEY, filterId],
    () => getProjectOptions(filterId),
    {
      retry: 0,
    },
  );

  // For testing, to be remove
  if (isError) {
    const { entities } = toCamel(mock) as any;
    return {
      projectOptions:
        entities?.map(({ properties }: any) => ({
          ...properties,
        })) || [],
      ...restProps,
    };
  }

  return {
    projectOptions:
      data?.entities?.map(({ properties }) => ({
        ...properties,
      })) || [],
    ...restProps,
  };
};
