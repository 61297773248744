import { useEffect, useState } from 'react';
import { useGetFilterAPI } from 'src/apis/filterAPI';
import { FilterPanelLayout } from 'src/components/layout/FilterPanelLayout';
import ResponseHandler from 'src/components/utils/ResponseHandler';
import { IFilterProps, TFilterID } from 'src/reducers/FilterReducer/FilterReducer';
import { safeParseJson } from 'src/utils/object';
import { INewSavedView, useGetSavedView } from 'src/apis/savedViewAPI';
import { useGetCurrentPageIdentifier } from 'src/stores/PageStore';
import { FilterSection } from 'src/components/layout/FilterPanelLayout/components/MainContainer/components/FilterSection';
import { parse } from 'date-fns';
import { MHSelectedFilterListStateKey, MHViewOptionsStateKey } from './localStorageKeys';

import { IOptionsChangeParameters } from './types/MoveHours';
import { Table } from './components/Table';
import { INITIAL_SAVED_VIEW } from './constants';
import { PeriodPicker } from '../ResourcePlanner/components/PeriodPicker';

export const MoveHours = () => {
  const pageIdentifier = useGetCurrentPageIdentifier();

  const { filterList, isError, isLoading } = useGetFilterAPI(pageIdentifier);
  const { savedView } = useGetSavedView(pageIdentifier);

  const [activeView, setActiveView] = useState<string>(savedView[0]?.filterViewId || '');
  const [changedViewOptions, setChangedViewOptions] = useState<any | undefined>(undefined);

  const getStartDateForViewOptions = () => new Date();

  const [initialDateEnd, setInitialDateEnd] = useState(getStartDateForViewOptions());
  const [initialDateStart, setInitialDateStart] = useState(getStartDateForViewOptions());

  const savedViewOptions = savedView?.find(
    (view: any) => view.filterViewId === activeView,
  )?.viewOptions;
  const textboxFilter = savedViewOptions?.find((filter: any) => filter.filterType === 'Textbox');

  useEffect(() => {
    if (textboxFilter) {
      setChangedViewOptions({
        searchText: textboxFilter.value,
      });
    } else {
      setChangedViewOptions(INITIAL_SAVED_VIEW.viewOptions);
    }
  }, [activeView, textboxFilter]);

  useEffect(() => {
    if (!changedViewOptions) {
      return;
    }
    localStorage.setItem(MHViewOptionsStateKey, JSON.stringify(changedViewOptions));
  }, [changedViewOptions]);

  const onViewOptionsChange = (items: IOptionsChangeParameters[]) => {
    const optionsPeriod = items.map((item) => {
      if (item.name === 'period-starts-at') {
        setInitialDateStart(parse(item.value, 'yyyy-MM-dd', new Date()));
      }

      if (item.name === 'period-ends-at') {
        setInitialDateEnd(parse(item.value, 'yyyy-MM-dd', new Date()));
      }

      return { [item.name]: item.value };
    });

    const optionItems = items.map((item) => ({ [item.name]: item.value }));
    const optionsToObject = Object.assign({}, ...optionsPeriod, ...optionItems);

    setChangedViewOptions({
      ...changedViewOptions,
      ...optionsToObject,
    });
  };

  const getSelectedFilterFromLocalStorage = (): Record<TFilterID, IFilterProps> => {
    const filterFromLocalStorage: Record<TFilterID, IFilterProps> =
      safeParseJson(localStorage.getItem(MHSelectedFilterListStateKey) || '') || {};
    if (filterList.length > 0) {
      const flatMapped = filterList.flatMap((x) => x.filterItems).map((x) => x?.name);
      Object.keys(filterFromLocalStorage).forEach((key) => {
        if (flatMapped.indexOf(key) === -1) {
          delete filterFromLocalStorage[key];
        }
      });
    }
    return filterFromLocalStorage;
  };

  return (
    <ResponseHandler isLoading={isLoading} isError={isError} isEmpty={filterList.length <= 0}>
      <FilterPanelLayout
        filterList={filterList}
        selectedFilterList={getSelectedFilterFromLocalStorage()}
        savedViewsList={savedView as unknown as INewSavedView[]}
        changedViewOptions={changedViewOptions}
        activeView={activeView}
        setActiveView={setActiveView}
      >
        <Table
          selectedViewOptions={changedViewOptions}
          onViewOptionsChange={onViewOptionsChange}
          filterSection={
            <FilterSection
              changedViewOptions={changedViewOptions}
              onViewOptionsChange={onViewOptionsChange}
              viewOptionsLeftFilter={
                <PeriodPicker
                  initialDateStart={initialDateStart}
                  initialDateEnd={initialDateEnd}
                  onChange={onViewOptionsChange}
                />
              }
            />
          }
        />
      </FilterPanelLayout>
    </ResponseHandler>
  );
};
