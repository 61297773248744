import { useQuery } from '@tanstack/react-query';
import { IFilterStoreProps } from 'src/reducers/FilterReducer';
import { getFetch } from 'src/utils/fetcher';
import { concatQuerys } from 'src/utils/url';
import isEmpty from 'lodash/isEmpty';
import { toCamel } from 'src/utils/object';
import { getMoveHours } from 'src/mocks/moveHours';
import { IMoveHoursWrapper } from '../types';

const MOVE_HOURS_URL_PATH = '/api/v2/move-hours';
export const MOVE_HOURS_MSW_STATUS_KEY = 'GetMoveHoursAPI';
export const MOVE_HOURS_KEY = 'MOVE_HOURS';

interface IParams {
  [key: string]: string;
}

const moveHoursFetch = (
  filters: string,
  params: { [key: string]: string },
): Promise<IMoveHoursWrapper> =>
  getFetch({
    path: `${MOVE_HOURS_URL_PATH}${concatQuerys(
      Object.keys(params).map((key) => ({
        param: key.replaceAll('-', ''),
        value: params[key],
      })),
    )}`.concat(filters),
    key: MOVE_HOURS_MSW_STATUS_KEY,
  });

export const useGetMoveHours = (
  { selectedFilterList }: Pick<IFilterStoreProps, 'selectedFilterList'>,
  params?: IParams,
) => {
  const items = Object.keys(selectedFilterList);
  const filteredList = `${concatQuerys(
    items
      .filter(
        (item) => selectedFilterList[item].values.map((value) => value.value).toString() !== '',
      )
      .map((item) => ({
        param: `${item}Ids`,
        value: selectedFilterList[item].values.map((value) => value.value).toString(),
        isAppended: true,
      })),
  )}${concatQuerys(
    items
      .filter(
        (item) => selectedFilterList[item].values.map((value) => value.value).toString() !== '',
      )
      .map((item) => ({
        param: `Is${item}Inclusive`,
        value: String(selectedFilterList[item].isInclude),
        isAppended: true,
      })),
  )}`;

  // Return {} if params is empty
  let filteredParams = {};
  if (params) {
    filteredParams = Object.entries(params).reduce<IParams>((acc, [key, value]) => {
      if (value !== '') {
        acc[key] = value;
      }
      return acc;
    }, {});
  }

  // Replace & with ? if params is empty
  const adjustFilteredLIst = filteredList.startsWith('&')
    ? `?${filteredList.substring(1)}`
    : filteredList;
  const formattedFilteredList = isEmpty(filteredParams) ? adjustFilteredLIst : filteredList;

  const { data, isError, isSuccess, ...restProps } = useQuery(
    [MOVE_HOURS_KEY, params || {}, selectedFilterList || {}],
    async () => {
      const response = await moveHoursFetch(formattedFilteredList, filteredParams || {});

      return response;
    },
    {
      // any previous data will be kept when fetching new data because the query key changed.
      keepPreviousData: true,
      staleTime: Infinity,
      retry: 0,
    },
  );

  const summations = {
    id: 'TOTAL',
    projectsCount: data?.properties?.projectsCount,
    projectActualHours: parseFloat(data?.properties?.totalProjectActualHours ?? '0'),
  };

  // For testing, to be remove
  if (isError) {
    const { entities, properties } = toCamel(getMoveHours) as any;
    return {
      data: entities?.map((item: any) => ({
        ...item.properties,
      })),
      summations,
      additionalCommentLabel: properties?.additionalCommentFieldName,
      isEmpty: (entities?.length ?? 0) <= 0,
      isError,
      isSuccess,
      ...restProps,
    };
  }

  return {
    data: data?.entities?.map((item) => ({
      ...item.properties,
    })),
    summations,
    additionalCommentLabel: data?.properties?.additionalCommentFieldName,
    isEmpty: (data?.entities?.length ?? 0) <= 0,
    isError,
    isSuccess,
    ...restProps,
  };
};
