import { Box, FormControl, MenuItem, Stack, Typography, ListSubheader } from '@mui/material';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridColDef,
  GridCsvExportMenuItem,
  GridToolbarExportContainer,
} from '@mui/x-data-grid-pro';
import { GridExcelExportMenuItem } from 'src/components/mui-components/DataGrid/utils';
import {
  cloneElement,
  Dispatch,
  isValidElement,
  ReactElement,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import table from 'src/assets/styles/variables/colors/states/table.module.scss';
import { useGetTaskOptions } from 'src/apis/moveHoursAPI';
import { Autocomplete, ToastifyAlert } from 'src/components/mui-components';
import { toast } from 'react-toastify';
import { HighlightMatchingText } from 'src/components/utils/HighlightMatchingText';
import { useExcelConfig } from './config';

interface IToolbarProps {
  selectedRowProject: any[];
  columns: GridColDef[];
  setColumnsButtonEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
  filterSection?: ReactNode;
  projectOptions?: any[];
}

interface FilterSectionProps {
  toolbarAction?: React.ReactNode;
}

export const Toolbar = ({
  selectedRowProject,
  columns,
  setColumnsButtonEl,
  filterSection,
  projectOptions,
}: IToolbarProps) => {
  const { t } = useTranslation('moveHours');
  const excelConfig = useExcelConfig();

  const [project, setProject] = useState<any>('');
  // Need task it to call the API
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [task, setTask] = useState<any>('');

  const { taskOptions } = useGetTaskOptions(project ?? '');

  const renderMenuItems = useCallback(
    (options: any, level = 1) =>
      options.map((option: any) => {
        if (option.items) {
          return [
            <ListSubheader key={option.id} sx={{ pl: level * 2, lineHeight: '33px' }}>
              {option.name}
            </ListSubheader>,
            ...renderMenuItems(option.items, level + 1),
          ];
        }
        return (
          <MenuItem key={option.id} value={option.name} sx={{ pl: level * 2 }}>
            {option.name}
          </MenuItem>
        );
      }),
    [],
  );

  useEffect(() => {
    setProject('');
    setTask('');
  }, [selectedRowProject]);

  const handleProjectChange = (newValue: any) => {
    setProject(newValue);
  };

  const handleTaskChange = (newValue: any) => {
    setTask(newValue);
    toast.success(<ToastifyAlert description="10 Project(s) successfully update." />, {
      autoClose: 5000,
      closeButton: false,
    });
  };

  const toolbarActionProps = {
    button: {
      sx: {
        minWidth: 10,
        pl: 2,
      },
    },
  };

  const toolbarAction = (
    <Stack direction="row">
      <GridToolbarColumnsButton ref={setColumnsButtonEl} slotProps={toolbarActionProps} />
      <GridToolbarExportContainer slotProps={toolbarActionProps}>
        <GridCsvExportMenuItem />
        <GridExcelExportMenuItem columns={columns} config={excelConfig} />
      </GridToolbarExportContainer>
    </Stack>
  );

  const FilterSectionToolbar = isValidElement(filterSection)
    ? cloneElement(filterSection as ReactElement<FilterSectionProps>, { toolbarAction })
    : filterSection;

  const hasSelectedRows = selectedRowProject.length > 0;

  return (
    <GridToolbarContainer sx={{ p: 0 }}>
      <Stack
        direction="row"
        justifyContent={hasSelectedRows ? 'flex-start' : 'flex-end'}
        width="100%"
      >
        {hasSelectedRows ? (
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignItems="center"
            width="100%"
            sx={{ backgroundColor: table.colorActionBar, p: 1, flexWrap: 'wrap' }}
          >
            <Typography variant="body2" sx={{ display: { xs: 'none', sm: 'block' } }}>
              {selectedRowProject.length} {t('SelectedText')}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Stack direction={{ xs: 'column', sm: 'row' }} gap={2} sx={{ flexWrap: 'wrap' }}>
              <FormControl sx={{ minWidth: 200 }} size="small">
                <Autocomplete
                  size="small"
                  label="Select project"
                  placeholder=""
                  options={projectOptions ?? []}
                  getOptionLabel={(option: any) => option.name}
                  isOptionEqualToValue={(o: any, v: any) => o.name === v.name}
                  componentsProps={{ popper: { style: { width: 200 } } }}
                  renderOption={(props, option, state) => (
                    <li
                      data-automation-id={`${option.name}Item`}
                      {...props}
                      key={`${option.name}-${state.index}`}
                    >
                      <HighlightMatchingText
                        name={option.name as string}
                        matchName={state.inputValue}
                      />
                    </li>
                  )}
                  onChange={(event, newValue) => {
                    handleProjectChange(newValue);
                  }}
                  sx={{ backgroundColor: 'white' }}
                  data-automation-id="ToolbarProjectNameDropdown"
                />
              </FormControl>
              <FormControl sx={{ minWidth: 200 }} size="small">
                <Autocomplete
                  size="small"
                  label="Select task"
                  placeholder=""
                  options={taskOptions ?? []}
                  getOptionLabel={(option: any) => option.name}
                  isOptionEqualToValue={(o: any, v: any) => o.name === v.name}
                  componentsProps={{ popper: { style: { width: 200 } } }}
                  disabled={!project || project === '0'}
                  renderOption={(props, option, state) => (
                    <li
                      data-automation-id={`${option.name}Item`}
                      {...props}
                      key={`${option.name}-${state.index}`}
                    >
                      <HighlightMatchingText
                        name={option.name as string}
                        matchName={state.inputValue}
                      />
                    </li>
                  )}
                  onChange={(event, newValue) => {
                    handleTaskChange(newValue);
                  }}
                  sx={{ backgroundColor: !project || project === '0' ? 'initial' : 'white' }}
                  data-automation-id="ToolbarTaskNameDropdown"
                />
              </FormControl>
            </Stack>
          </Stack>
        ) : (
          <Box sx={{ p: 1 }} width="100%">
            {FilterSectionToolbar}
          </Box>
        )}
      </Stack>
    </GridToolbarContainer>
  );
};
