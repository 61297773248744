import { GRID_CHECKBOX_SELECTION_FIELD } from '@mui/x-data-grid-pro';
import { ISavedView } from 'src/apis/savedViewAPI';

export const INITIAL_SAVED_VIEW: ISavedView = {
  title: '',
  isDefault: false,
  createdDate: new Date(),
  updatedDate: new Date(),
  filters: {},
  viewOptions: {
    searchText: '',
  },
};

export const SEARCH_TEXT_FILTER = {
  name: 'SearchText',
  value: '',
  isInclusive: true,
  filterType: 'Textbox',
};

export const preDefinedFilters = ['MyActiveProjects', 'ActiveProjects', 'AllProjects'];

export const hiddenFields = [GRID_CHECKBOX_SELECTION_FIELD, 'split'];
