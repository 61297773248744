import {
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  ToastifyAlert,
} from 'src/components/mui-components';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const projectOptions = [
  { value: '1', label: 'Project 1' },
  { value: '2', label: 'Project 2' },
  { value: '3', label: 'Project 3' },
];

// eslint-disable-next-line react-hooks/exhaustive-deps
const taskOptions = [
  { value: '1', label: 'Task 1' },
  { value: '2', label: 'Task 2' },
  { value: '3', label: 'Task 3' },
];

export const SplitDialog = ({ showSplit, setShowSplit }: any) => {
  const { t } = useTranslation('moveHours');

  const handleOk = () => {
    setShowSplit(false);
    toast.success(<ToastifyAlert description="Time registrations split successfully" />, {
      autoClose: 5000,
      closeButton: false,
    });
  };

  return (
    <Dialog
      open={!!showSplit}
      onClose={() => setShowSplit(false)}
      aria-labelledby="dialog-time-registration"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="dialog-time-registration">{t('SplitTimeRegistrationText')}</DialogTitle>
      <DialogContent>
        <Stack gap={1.5}>
          <Card variant="outlined">
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <Typography>{t('TableHeaderProjectName')}:</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography fontWeight={500}>_TIM - New reporting</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography>{t('TableHeaderWbs')}:</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography fontWeight={500}>1</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography>{t('TableHeaderTaskName')}:</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography fontWeight={500}>Work</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography>{t('TableHeaderDate')}:</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography fontWeight={500}>27-02-2024</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography>{t('TotalHoursText')}:</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography fontWeight={500}>0,4</Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Stack gap={1}>
            <Typography fontWeight={600} component="h3">
              {t('DistributeBetweenTasksText')}
            </Typography>
            {/* <Typography>View: Hide comments & Hello World</Typography> */}
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Card variant="outlined">
                  <CardContent>
                    <Stack gap={2}>
                      {/* <Typography variant="h3" component="div" sx={{ mb: 1 }}>
                        Split 1
                      </Typography> */}

                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          {t('TableHeaderProjectName')}
                        </InputLabel>
                        <Select
                          value="1"
                          label={t('TableHeaderProjectName')}
                          // eslint-disable-next-line @typescript-eslint/no-unused-vars
                          onChange={(event) => {
                            // eslint-disable-next-line no-alert
                            alert('change');
                          }}
                          size="small"
                          fullWidth
                        >
                          {projectOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          {t('TableHeaderTaskName')}
                        </InputLabel>
                        <Select
                          value="1"
                          label={t('TableHeaderTaskName')}
                          // eslint-disable-next-line @typescript-eslint/no-unused-vars
                          onChange={(event) => {
                            // eslint-disable-next-line no-alert
                            alert('change');
                          }}
                          size="small"
                          fullWidth
                        >
                          {taskOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <TextField
                        data-automation-id="HoursInput"
                        label={t('HoursText')}
                        ariaLabel="hours-input"
                        variant="outlined"
                        size="small"
                        defaultValue="0,2"
                        required
                        sx={{ width: '50%' }}
                      />
                      <TextField
                        data-automation-id="CommentInput"
                        label={t('TableHeaderComment')}
                        ariaLabel="comment-input"
                        variant="outlined"
                        size="small"
                        defaultValue="My comment"
                      />
                      <TextField
                        data-automation-id="AdditionalTextInput"
                        label="Additional Text" // CHange to correct additional text
                        ariaLabel="additional-input"
                        variant="outlined"
                        size="small"
                        multiline
                      />
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card variant="outlined">
                  <CardContent>
                    <Stack gap={2}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          {t('TableHeaderProjectName')}
                        </InputLabel>
                        <Select
                          value="1"
                          label={t('TableHeaderProjectName')}
                          // eslint-disable-next-line @typescript-eslint/no-unused-vars
                          onChange={(event) => {
                            // eslint-disable-next-line no-alert
                            alert('change');
                          }}
                          size="small"
                          fullWidth
                        >
                          {projectOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          {t('TableHeaderTaskName')}
                        </InputLabel>
                        <Select
                          value="1"
                          label={t('TableHeaderTaskName')}
                          // eslint-disable-next-line @typescript-eslint/no-unused-vars
                          onChange={(event) => {
                            // eslint-disable-next-line no-alert
                            alert('change');
                          }}
                          size="small"
                          fullWidth
                        >
                          {taskOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <TextField
                        data-automation-id="HoursInput"
                        label={t('HoursText')}
                        ariaLabel="hours-input"
                        variant="outlined"
                        size="small"
                        defaultValue="0,2"
                        required
                        sx={{ width: '50%' }}
                      />
                      <TextField
                        data-automation-id="CommentInput"
                        label={t('TableHeaderComment')}
                        ariaLabel="comment-input"
                        variant="outlined"
                        size="small"
                        defaultValue="My comment"
                      />
                      <TextField
                        data-automation-id="AdditionalTextInput"
                        label="Additional Text" // Change to correct additional text
                        ariaLabel="additional-input"
                        variant="outlined"
                        size="small"
                        multiline
                      />
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => setShowSplit(false)}>
          {t('CancelButton')}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setShowSplit(false);
            handleOk();
          }}
          data-automation-id="OkButton"
        >
          {t('OkButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
